import { render, staticRenderFns } from "./StffAdd.vue?vue&type=template&id=48fd8074&scoped=true&"
import script from "./StffAdd.vue?vue&type=script&lang=js&"
export * from "./StffAdd.vue?vue&type=script&lang=js&"
import style0 from "./StffAdd.vue?vue&type=style&index=0&id=48fd8074&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48fd8074",
  null
  
)

export default component.exports