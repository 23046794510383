<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group">
        <el-button type="primary" @click="submit('stffForm')" size="small">保存</el-button>
        <el-button @click="cancel" size="small">取消</el-button>
      </div>
      <el-form ref="stffForm" :model="stffForm" :rules="rules" label-width="120px" size="mini" :show-message="false">
        <el-row :gutter="24">
          <el-col :md="8">
            <el-form-item label="员工头像">
              <el-upload
                class="avatar-uploader"
                :action="uploadUrl"
                :data="dataBody"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="stffForm.imge_id" :src="stffForm.imge_url" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="员工编号" prop="stff_no" required>
              <el-input v-model="stffForm.stff_no" maxlength="10" show-word-limit placeholder="请填写员工编号"></el-input>
            </el-form-item>
            <el-form-item label="员工姓名" prop="stff_name" required>
              <el-input :value="stffForm.stff_name" maxlength="10" placeholder="请填写员工姓名（中文）" show-word-limit @input="stffNameChange"></el-input>
            </el-form-item>
            <el-form-item label="员工英文名" prop="stff_name">
              <el-input
                v-model="stffForm.stff_ename"
                maxlength="20"
                @input="stffForm.stff_ename = helper.NotkeepChin(stffForm.stff_ename)"
                show-word-limit
                placeholder="请填写员工英文名"
              ></el-input>
            </el-form-item>
            <el-form-item label="身份证号" prop="stff_identity">
              <el-input v-model="stffForm.stff_identity" maxlength="18" placeholder="请填写身份证号" show-word-limit @input="idChange"></el-input>
            </el-form-item>
            <el-form-item label="员工性别">
              <el-input v-model="stffForm.stff_gender" placeholder="根据身份信息自动填充" disabled> </el-input>
            </el-form-item>
            <el-form-item label="公司抬头" prop="cptt_id" :rules="[{ required: true }]">
              <el-select v-model="stffForm.cptt_id" clearable placeholder="请选择公司抬头" @change="selectCpttName" @visible-change="getCpttV1($event)">
                <el-option v-for="item in cpttGroupOpt" :key="item.cptt_id" :label="item.cptt_name" :value="item.cptt_id"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="部门名称" prop="dept_id" :rules="[{ required: true }]">
              <el-select v-model="stffForm.dept_id" clearable placeholder="请选择部门" @change="openTeam" @visible-change="getDeptV1($event)">
                <el-option v-for="item in deptGroupOpt" :key="item.dept_id" :label="item.dept_name" :value="item.dept_id"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="小组名称">
              <el-select
                v-model="stffForm.dept_team_id"
                placeholder="请选择小组（部门未选时禁选）"
                clearable
                @visible-change="getTeamByDept($event)"
                :disabled="disabled"
              >
                <el-option v-for="item in teamGroupOpt" :key="item.dept_team_id" :label="item.dept_team_name" :value="item.dept_team_id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="员工岗位">
              <el-input v-model="stffForm.stff_job" maxlength="18" show-word-limit placeholder="请填写员工岗位">
                <!-- <el-option
                 v-for="item in stffJobGroupOpt"
                 :key="item.posi_id"
                 :label="item.posi_name"
                 :value="item.posi_name">
             </el-option> -->
              </el-input>
            </el-form-item>
            <el-form-item label="上级领导">
              <select-input
                :selTableData="selTableData"
                :tableHeader="staffTableHeader"
                @getSelTableRow="getSelTableRow"
                :inputVal="stffForm.stff_leader_name"
                :disabled="disabled2"
                :isShowCol="false"
                :rowSelect="rowSelect"
                placeholder="请选择上级领导"
              >
              </select-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="聘用类型">
              <el-input v-model="stffForm.stff_hire_tap" placeholder="根据最新合同类型自动填充" disabled></el-input>
            </el-form-item>
            <el-form-item label="入职时间" prop="stffEnterTime" required>
              <el-date-picker v-model="stffForm.stffEnterTime" placeholder="请选择入职时间" type="date" @change="enterTimeChange"></el-date-picker>
            </el-form-item>
            <el-form-item label="离职时间" prop="stffLeaveTime">
              <el-date-picker v-model="stffForm.stffLeaveTime" placeholder="请选择离职时间" type="date" @change="LeaveTimeChange"></el-date-picker>
            </el-form-item>
            <el-form-item label="员工生日">
              <el-input v-model="stffForm.stff_birthday" placeholder="根据身份信息自动填充"></el-input>
            </el-form-item>
            <el-form-item label="员工年龄" prop="stff_age">
              <el-input v-model="stffForm.stff_age" placeholder="根据身份信息自动填充" disabled></el-input>
            </el-form-item>
            <el-form-item label="员工司龄" prop="stff_seniority">
              <el-input v-model="stffForm.stff_seniority" placeholder="根据入职或者离职时间自动填充" disabled></el-input>
            </el-form-item>
            <el-form-item label="劳动手册">
              <el-select v-model="stffForm.stff_handbook" clearable placeholder="请选择劳动手册签订情况" @visible-change="getHandBook($event)">
                <el-option v-for="item in handBookGroupOpt" :key="item.value" :label="item.label" :value="item.label"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="保密协议">
              <el-select v-model="stffForm.stff_secret_flag" placeholder="请选择保密协议签订情况" clearable>
                <el-option label="未签" value="0"></el-option>
                <el-option label="已签" value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="公积金号" prop="stff_fund_no">
              <el-input
                v-model="stffForm.stff_fund_no"
                maxlength="12"
                show-word-limit
                @change="jinChange"
                @input="stffForm.stff_fund_no = helper.keepTNum(stffForm.stff_fund_no)"
                placeholder="请填写公积金号"
              ></el-input>
            </el-form-item>
            <el-form-item label="开户银行" prop="stff_bank_name">
              <el-select v-model="stffForm.stff_bank_name" placeholder="请选择开户银行" clearable>
                <el-option label="中国工商银行" value="中国工商银行"></el-option>
                <el-option label="交通银行" value="交通银行"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="银行卡号" prop="stff_bank_no">
              <el-input
                v-model="stffForm.stff_bank_no"
                @input="stffForm.stff_bank_no = helper.keepTNum(stffForm.stff_bank_no)"
                maxlength="19"
                show-word-limit
                placeholder="请填写银行卡号"
              ></el-input>
            </el-form-item>
            <el-form-item label="婚育状况" prop="stff_marry">
              <el-select v-model="stffForm.stff_marry" placeholder="请选择婚育状况" clearable @visible-change="getMarrOpts($event)">
                <el-option v-for="item in marrGroupOpts" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="子女情况" prop="stff_children">
              <el-input v-model="stffForm.stff_children" :disabled="merryDisabled" maxlength="50" placeholder="请填写员工子女情况" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="员工民族" prop="stff_race">
              <!-- <el-input v-model="stffForm.stff_race"  maxlength="10" show-word-limit placeholder="请填写员工民族"></el-input> -->
              <el-input
                v-model="stffForm.stff_race"
                maxlength="5"
                @input="stffForm.stff_race = helper.keepChin(stffForm.stff_race)"
                show-word-limit
                placeholder="请填写员工民族"
              ></el-input>
            </el-form-item>
            <el-form-item label="政治面貌" prop="stff_politic">
              <el-select v-model="stffForm.stff_politic" clearable placeholder="请选择员工政治面貌" @visible-change="getPoliticOpts($event)">
                <el-option v-for="item in politicGroupOpts" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="员工血型" prop="stff_blood">
              <el-select v-model="stffForm.stff_blood" placeholder="请选择员工血型" clearable @visible-change="getBloodOpts($event)">
                <el-option v-for="item in bloodGroupOpts" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="员工电话">
              <el-input :value="stffForm.stff_phone" @input="phoneChange($event)" maxlength="11" placeholder="请填写电话"></el-input>
            </el-form-item>
            <el-form-item label="最高学历">
              <el-select v-model="stffForm.stff_education" placeholder="请选择最高学历" clearable @visible-change="getEducOpts($event)">
                <el-option v-for="item in educGroupOpts" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="员工学校">
              <el-input v-model="stffForm.stff_school" maxlength="30" show-word-limit placeholder="请填写学校"></el-input>
            </el-form-item>
            <el-form-item label="员工专业">
              <!-- <el-input v-model="stffForm.stff_major" maxlength="30" show-word-limit placeholder="请填写专业"></el-input> -->
              <el-input :value="stffForm.stff_major" maxlength="30" placeholder="请填写专业" show-word-limit @input="majorChange($event)"></el-input>
            </el-form-item>
            <el-form-item label="员工户籍">
              <el-input v-model="stffForm.stff_place" maxlength="10" show-word-limit placeholder="请填写员工户籍所在地"></el-input>
            </el-form-item>
            <el-form-item label="户口地址">
              <el-input v-model="stffForm.stff_place_address" show-word-limit placeholder="请填写员工户口详细地址"></el-input>
            </el-form-item>
            <el-form-item label="当前住址">
              <el-input v-model="stffForm.stff_address" maxlength="30" show-word-limit placeholder="请填写员工现住址"></el-input>
            </el-form-item>
            <el-form-item label="打样编号">
              <el-input
                v-model="stffForm.stff_number"
                maxlength="30"
                show-word-limit
                placeholder="请填写打样编号"
                @input="val => (stffForm.stff_number = helper.pureE(val))"
              ></el-input>
            </el-form-item>
            <el-form-item label="员工简介">
              <el-input v-model="stffForm.stff_intro" :rows="1" maxlength="30" placeholder="请填写员工简介" show-word-limit type="textarea"></el-input>
            </el-form-item>
            <el-form-item label="分机号">
              <el-input v-model="stffForm.extension_number" maxlength="5" placeholder="请填写分机号" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="电子邮箱">
              <el-input v-model="stffForm.stff_email" maxlength="30" placeholder="请填写电子邮箱" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="邮箱授权码">
              <el-input v-model="stffForm.stff_email_password" maxlength="50" placeholder="请填写邮箱授权码" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="离职原因">
              <el-input
                type="textarea"
                :rows="3"
                v-model="stffForm.stff_leave_reason"
                maxlength="50"
                show-word-limit
                placeholder="请填写员工离职原因"
              ></el-input>
            </el-form-item>
            <el-form-item label="备注">
              <el-input v-model="stffForm.stff_remark" :rows="3" maxlength="100" placeholder="请填写备注" show-word-limit type="textarea"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="vd_button">
          <el-button type="primary" plain size="small" @click="addRow()">添加</el-button>
          <el-button type="danger" plain size="small" @click="delLabr()">删除</el-button>
        </div>
        <el-table ref="labr" :data="stffForm.stff_labr_list" border class="vg_mt_16" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="48" align="center"></el-table-column>
          <el-table-column label="序号" width="48" align="center">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <span class="vg_deep_red">合同类型</span>
            </template>
            <template slot-scope="scope">
              <el-form-item label-width="0" :prop="'stff_labr_list.' + scope.$index + '.stff_labr_cont'" :rules="{ required: true }">
                <el-select
                  v-model="stffForm.stff_labr_list[scope.$index].stff_labr_cont"
                  placeholder="请选择合同类型"
                  clearable
                  @change="contChange(scope.row)"
                >
                  <el-option v-for="item in contGroupOpt" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="合同期限">
            <template slot-scope="scope">
              <el-form-item label-width="0">
                <el-input
                  v-model="stffForm.stff_labr_list[scope.$index].stff_labr_period"
                  maxlength="10"
                  show-word-limit
                  placeholder="请填写合同期限"
                  :disabled="stffForm.stff_labr_list[scope.$index].stff_labr_cont == 7"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <span class="vg_deep_red">合同起始日期</span>
            </template>
            <template slot-scope="scope">
              <el-form-item label-width="0" :prop="'stff_labr_list.' + scope.$index + '.stff_labr_in_time'" :rules="{ required: true }">
                <el-date-picker
                  type="date"
                  placeholder="请选择合同起始日期"
                  v-model="stffForm.stff_labr_list[scope.$index].stff_labr_in_time"
                  @change="changePic(scope.row)"
                  :disabled="(disabled = stffForm.stff_labr_list[scope.$index].stff_labr_cont == 7)"
                ></el-date-picker>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="合同结束日期">
            <template slot-scope="scope">
              <el-form-item label-width="0">
                <el-date-picker
                  type="date"
                  placeholder="请选择合同结束日期"
                  v-model="stffForm.stff_labr_list[scope.$index].stff_labr_out_time"
                  :disabled="(disabled = stffForm.stff_labr_list[scope.$index].stff_labr_cont == 7)"
                ></el-date-picker>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="试用期限">
            <template slot-scope="scope">
              <el-form-item label-width="0">
                <el-select
                  v-model="stffForm.stff_labr_list[scope.$index].stff_labr_try_date"
                  clearable
                  @change="selectDate(scope.row)"
                  placeholder="请选择试用期限"
                  :disabled="stffForm.stff_labr_list[scope.$index].try_time_flag || !stffForm.stff_labr_list[scope.$index].stff_labr_in_time"
                >
                  <el-option v-for="item in trialOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="试用到期日">
            <template slot-scope="scope">
              <el-form-item label-width="0">
                <el-date-picker
                  type="date"
                  placeholder="请选择试用到期日"
                  v-model="stffForm.stff_labr_list[scope.$index].stff_labr_try_time"
                  @change="tryChange(scope.row)"
                  :disabled="stffForm.stff_labr_list[scope.$index].try_time_flag || !stffForm.stff_labr_list[scope.$index].stff_labr_in_time"
                >
                  <!--  -->
                </el-date-picker>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="实际转正日期">
            <template slot-scope="scope">
              <el-form-item label-width="0">
                <el-date-picker
                  type="date"
                  placeholder="请选择实际转正日期"
                  v-model="stffForm.stff_labr_list[scope.$index].stff_probation_date"
                  @change="tryChange(scope.row)"
                >
                  <!--  -->
                </el-date-picker>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="签订次数">
            <template slot-scope="scope">
              <el-form-item label-width="0">
                <el-select
                  v-model="stffForm.stff_labr_list[scope.$index].stff_labr_times"
                  placeholder="请选择合同签订次数"
                  :disabled="
                    (stffForm.stff_labr_list[scope.$index].try_time_flag && stffForm.stff_labr_list[scope.$index].stff_labr_cont == 1) ||
                    (stffForm.stff_labr_list[scope.$index].try_time_flag && stffForm.stff_labr_list[scope.$index].stff_labr_cont == 3) ||
                    (stffForm.stff_labr_list[scope.$index].try_time_flag && stffForm.stff_labr_list[scope.$index].stff_labr_cont == 7)
                  "
                >
                  <el-option v-for="item in signGroupOpt" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="公司抬头">
            <template slot-scope="scope">
              <el-form-item label-width="0">
                <el-input
                  v-model="stffForm.stff_labr_list[scope.$index].cptt_name"
                  placeholder="请填写公司抬头"
                  :disabled="stffForm.stff_labr_list[scope.$index].stff_labr_cont == 7"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { stffAPI } from '@api/modules/staff';
import { cpttAPI } from '@api/modules/comptitle';
import { deptAPI } from '@api/modules/department';
import { dataAPI } from '@api/modules/data';
import { imgeAPI } from '@api/modules/imge';
import selectInput from '@/views/component/selectInput';
import { staffTableHeader } from '@/views/component/tableHeader/staffTableHeader';
import options from '@/views/component/common/options';
import helper from '@assets/js/helper';
import { cloneDeep } from 'lodash';

export default {
  name: 'StffAdd.vue',
  components: {
    selectInput
  },
  data() {
    var vue = this;
    return {
      stffForm: {
        stff_no: '',
        stff_name: '',
        stff_leader: null,
        stff_leader_name: '',
        cptt_id: null,
        dept_id: null,
        dept_team_id: null,
        stffEnterTime: null,
        stffLeaveTime: null,
        stff_gender: '',
        stffBirthday: null,
        stff_phone: '',
        stff_identity: null,
        stff_education: '',
        stff_school: '',
        stff_major: '',
        stff_address: '',
        stff_job: '',
        stff_fund_no: '',
        stff_bank_no: '',
        stff_handbook: '',
        stff_secret_flag: null,
        stff_bank_name: '',
        stff_place: '',
        stff_place_address: '',
        stff_race: '',
        stff_politic: '',
        stff_marry: '',
        stff_blood: '',
        stff_leave_reason: '',
        stff_children: '',
        stff_remark: '',
        imge_id: null,
        imge_url: '',
        stff_labr_list: [],
        stff_labr_try_date: 0,
        stff_hire_tap: '',
        stff_number: '',
        stff_email: '',
        extension_number: ''
      },
      //试用期限
      trialOptions: [
        {
          value: '0',
          label: '无'
        },
        {
          value: '1',
          label: '一个月'
        },
        {
          value: '2',
          label: '两个月'
        },
        {
          value: '3',
          label: '三个月'
        },
        {
          value: '4',
          label: '四个月'
        },
        {
          value: '5',
          label: '五个月'
        },
        {
          value: '6',
          label: '六个月'
        }
      ],
      rowSelect: [],
      key: 0,
      compTitle: '',
      disabled: true,
      disabled2: false,
      merryDisabled: false,
      selTableData: [],
      cpttGroupOpt: [],
      deptGroupOpt: [],
      teamGroupOpt: [],
      contGroupOpt: [], // 合同类型
      signGroupOpt: [], // 第几签
      stffJobGroupOpt: [], // 员工岗位
      handBookGroupOpt: [], // 劳动手册
      marrGroupOpts: [], // 婚育情况
      politicGroupOpts: [], // 政治面貌
      bloodGroupOpts: [], // 血型
      educGroupOpts: [], // 学历
      selectionsList: [],
      staffTableHeader: [],
      rules: {
        stff_identity: [{ max: 18, min: 18, message: '', trigger: 'blur' }]
      },
      uploadUrl: helper.modePrepend(imgeAPI.uploadImage),
      dataBody: {
        cut: true,
        x: 200,
        y: 200,
        perm_id: this.$route.query.perm_id
      }
    };
  },
  created() {
    this.getSelectTable();
    this.staffTableHeader = staffTableHeader;
    this.contGroupOpt = options.labOpts;
    this.signGroupOpt = options.signOpts;
  },
  watch: {
    'stffForm.dept_id': function (newVal, oldVal) {
      if (oldVal) {
        if (newVal !== oldVal) {
          this.stffForm.dept_team_id = null;
          this.teamGroupOpt = [];
        }
      }
    },
    'stffForm.stff_marry': function (newVal) {
      if (newVal === '未婚未育' || newVal === '已婚未育') {
        this.merryDisabled = true;
      } else {
        this.merryDisabled = false;
      }
    },
    'stffForm.stff_enter_time'(newVal, oldVal) {
      if (newVal) {
        if (this.stffForm.stff_leave_time) {
          this.stffForm.stff_seniority = this.helper.toSeniority(this.stffForm.stff_enter_time / 1000, this.stffForm.stff_leave_time / 1000);
        } else {
          this.stffForm.stff_seniority = this.helper.toSeniority(this.stffForm.stff_enter_time / 1000, 0);
        }
      } else {
        this.stffForm.stff_seniority = null;
      }
    },
    'stffForm.stff_labr_list': {
      handler: function (newVal) {
        newVal.forEach(item => {
          if (item.stff_labr_cont === 4) {
            item.try_time_flag = false;
          } else {
            item.try_time_flag = true;
            item.stff_labr_try_time = null;
            item.stff_labr_times = null;
          }
        });
      },
      deep: true
    },
    'stffForm.dept_team_id': function (newVal) {
      if (newVal === 0) {
        this.stffForm.dept_team_id = null;
      }
    }
  },
  methods: {
    phoneChange(event) {
      return (this.stffForm.stff_phone = this.helper.purephone(event));
    },
    majorChange(val) {
      return (this.stffForm.stff_major = this.helper.pureC(val));
    },
    // 判断公积金不能小于9位数
    jinChange(val) {
      if (val.length < 9) {
        this.stffForm.stff_fund_no = '';
      }
    },
    //判断身份证
    idChange(val) {
      let ze = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/g;
      if (ze.test(val)) {
        this.stffForm.stff_age = this.helper.mathUserInfo(cloneDeep(val), 3);
        this.stffForm.stff_birthday = this.helper.mathUserInfo(cloneDeep(val), 1);
        this.stffForm.stff_gender = this.helper.mathUserInfo(cloneDeep(val), 2);
      }
    },
    // 获取传递selTableData
    getSelectTable() {
      get(stffAPI.getAllStffsV1).then(res => {
        if (res.data.code === 0) {
          this.selTableData = res.data.data;
        }
      });
    },
    // 获取双击selTableData的row
    getSelTableRow(val) {
      this.stffForm.stff_leader = val.stff_id;
      this.stffForm.stff_leader_name = val.stff_name;
    },
    // 获取员工岗位
    getStffJob(flag) {
      if (flag === true && this.stffJobGroupOpt.length === 0) {
        get(dataAPI.getALLPosis).then(res => {
          if (res.data.code === 0) {
            this.stffJobGroupOpt = res.data.data;
          }
        });
      }
    },
    // 获取员工手册
    getHandBook(flag) {
      if (flag === true && this.handBookGroupOpt.length === 0) {
        this.handBookGroupOpt = options.handBookOpts;
      }
    },
    // 获取婚育情况
    getMarrOpts(flag) {
      if (flag === true && this.marrGroupOpts.length === 0) {
        this.marrGroupOpts = options.marrOpts;
      }
    },
    // 获取政治面貌
    getPoliticOpts(flag) {
      if (flag === true && this.politicGroupOpts.length === 0) {
        this.politicGroupOpts = options.politicOpts;
      }
    },
    // 获取血型
    getBloodOpts(flag) {
      if (flag === true && this.bloodGroupOpts.length === 0) {
        this.bloodGroupOpts = options.bloodOpts;
      }
    },
    // 获取学历
    getEducOpts(flag) {
      if (flag === true && this.educGroupOpts.length === 0) {
        this.educGroupOpts = options.educOpts;
      }
    },
    // 成功上传
    handleAvatarSuccess(res) {
      if (res.code == 0) {
        this.stffForm.imge_id = res.data.imge_id;
        this.stffForm.imge_url = this.helper.picUrl(res.data.imge_url, 'l');
      } else {
        this.$message({ type: 'error', message: '上传失败' });
      }
    },
    // 上传头像前
    beforeAvatarUpload() {},
    // 提交表单
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              return false;
            }
          });
        })
        .catch(() => {
          this.$message.info('已取消保存');
        });
    },
    // 保存
    saveInfo() {
      let stffFormBody = this.stffForm;
      stffFormBody.stff_enter_time = parseInt(new Date(this.stffForm.stffEnterTime).getTime() / 1000);
      stffFormBody.stff_leave_time = parseInt(new Date(this.stffForm.stffLeaveTime).getTime() / 1000);
      let stffFormList = this.stffForm.stff_labr_list;
      for (let i = 0; i < stffFormList.length; i++) {
        stffFormList[i].stff_labr_in_time = parseInt(new Date(stffFormList[i].stff_labr_in_time).getTime() / 1000);
        stffFormList[i].stff_labr_out_time = parseInt(new Date(stffFormList[i].stff_labr_out_time).getTime() / 1000);
        stffFormList[i].stff_labr_try_time = parseInt(new Date(stffFormList[i].stff_labr_try_time).getTime() / 1000);
        stffFormList[i].stff_probation_date = parseInt(new Date(stffFormList[i].stff_probation_date).getTime() / 1000);
      }
      post(stffAPI.addStff, stffFormBody)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            this.resetForm('stffForm');
            this.stffForm.stff_labr_list = [];
            this.stffForm.imge_id = null;
            this.jump('/stff_edit', {
              perm_id: this.$route.query.perm_id,
              form_id: res.data.data.form_id
            });
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('stffForm');
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.stff_labr_list = [];
    },
    //添加
    addRow() {
      let item = {
        stff_labr_cont: null,
        stff_labr_period: '',
        stff_labr_in_time: null,
        stff_labr_out_time: null,
        stff_labr_try_time: null,
        try_time_flag: true,
        stff_labr_times: null,
        cptt_name: this.compTitle,
        key: this.key
      };
      this.key = this.key + 1;
      this.stffForm.stff_labr_list.push(item);
    },
    //
    handleSelectionChange(selection) {
      this.selectionsList = selection;
    },
    //多选删除
    delLabr() {
      for (let i = 0; i < this.selectionsList.length; i++) {
        this.stffForm.stff_labr_list.forEach(item => {
          if (item.key === this.selectionsList[i].key) {
            let currentIndex = this.stffForm.stff_labr_list.indexOf(item);
            this.stffForm.stff_labr_list.splice(currentIndex, 1);
          }
        });
      }
    },
    // 获取cpttId
    getCpttV1(flag) {
      if (flag === true && this.cpttGroupOpt.length === 0) {
        get(cpttAPI.getAllCpttsV1).then(res => {
          if (res.data.code === 0) {
            this.cpttGroupOpt = res.data.data;
          }
        });
      }
    },
    // 获取deptId
    getDeptV1(flag) {
      if (flag === true && this.deptGroupOpt.length === 0) {
        get(deptAPI.getAllDeptsV1).then(res => {
          if (res.data.code === 0) {
            this.deptGroupOpt = res.data.data;
          }
        });
      }
    },
    // 获取deptTeamId
    getTeamByDept(flag) {
      if (flag === true && this.teamGroupOpt.length === 0) {
        get(deptAPI.getTeamsByDeptId, { dept_id: this.stffForm.dept_id }).then(res => {
          if (res.data.code === 0) {
            this.teamGroupOpt = res.data.data;
          }
        });
      }
    },
    // 获取公司的抬头
    selectCpttName(params) {
      const parObj = this.cpttGroupOpt.find(item => {
        return item.cptt_id === params;
      });
      this.compTitle = parObj.cptt_name;
    },
    //开启选择小组
    openTeam(val) {
      if (val) {
        this.stffForm.dept_id = val;
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
    //姓名只能输入中文
    stffNameChange(Val) {
      this.stffForm.stff_name = this.helper.pureC(Val);
    },
    //选择到期日
    selectDate(row) {
      if (row.stff_labr_try_date === 0) {
        row.stff_labr_try_time = '';
        row.stff_probation_date = '';
        return;
      }
      if (!row.stff_labr_in_time) {
        return;
      }
      //获取第一个值
      let newDate = new Date(row.stff_labr_in_time.getTime());
      let m = newDate.getMonth(); //月份
      let d = newDate.getDate(); //天数
      let mm = m + 1 + (row.stff_labr_try_date - 0); //即将到达的年份
      let dd = new Date(JSON.parse(JSON.stringify(newDate))); //新的时间
      let year = new Date(dd.setMonth(m + (row.stff_labr_try_date - 0))).getFullYear(); //即将到达的年份
      mm = mm <= 12 ? mm : mm - 12;
      if (d === 1) {
        newDate.setMonth(m + (row.stff_labr_try_date - 0));
        newDate = newDate.getTime() - 3600 * 24;
        row.stff_labr_try_time = newDate;
        return;
      }
      if (m + 1 == 2) {
        if ((year % 4 == 0 && year % 100 != 0) || year % 400 == 0) {
          if (d == 29) {
            newDate.setMonth(m + (row.stff_labr_try_date - 0));
            let day = m + 1 + (row.stff_labr_try_date - 0);
            switch (day) {
              case 1:
              case 3:
              case 5:
              case 7:
              case 8:
              case 10:
              case 12:
                newDate.setDate(30);
                break;
              case 4:
              case 6:
              case 9:
              case 11:
                newDate.setDate(29);
                break;
              default:
                newDate.setDate(d - 1);
                break;
            }
            row.stff_labr_try_time = newDate;
            return;
          }
        } else {
          if (d == 28) {
            newDate.setMonth(m + (row.stff_labr_try_date - 0));
            let day = m + 1 + (row.stff_labr_try_date - 0);
            switch (day) {
              case 1:
              case 3:
              case 5:
              case 7:
              case 8:
              case 10:
              case 12:
                newDate.setDate(30);
                break;
              case 4:
              case 6:
              case 9:
              case 11:
                newDate.setDate(29);
                break;
              default:
                newDate.setDate(d - 1);
                break;
            }
            row.stff_labr_try_time = newDate;
            return;
          }
        }
      }

      if (d <= 28) {
        newDate.setDate(d - 1);
      } else {
        if (d === 31) {
          switch (mm) {
            case 1:
            case 3:
            case 5:
            case 7:
            case 8:
            case 10:
            case 12:
              newDate.setDate(30);
              break;
            case 2:
              if ((year % 4 == 0 && year % 100 != 0) || year % 400 == 0) {
                newDate.setDate(29);
              } else {
                newDate.setDate(28);
              }
              break;
            default:
              newDate.setDate(30);
          }
        } else if (d == 30) {
          switch (mm) {
            case 1:
            case 3:
            case 5:
            case 7:
            case 8:
            case 10:
            case 12:
              newDate.setDate(29);
              break;
            case 2:
              if ((year % 4 == 0 && year % 100 != 0) || year % 400 == 0) {
                newDate.setDate(29);
              } else {
                newDate.setDate(28);
              }
              break;
            default:
              newDate.setDate(29);
          }
        } else if (d == 29) {
          if (mm === 2) {
            if ((year % 4 == 0 && year % 100 != 0) || year % 400 == 0) {
              newDate.setDate(28);
            } else {
              newDate.setDate(28);
            }
          } else {
            newDate.setDate(d - 1);
          }
        }
      }
      newDate.setMonth(m + (row.stff_labr_try_date - 0));

      row.stff_labr_try_time = newDate;
      row.stff_probation_date = newDate.getTime() + 60 * 60 * 24;
    },
    picChange(row) {
      this.selectDate(row);
    },
    //点击清空选择
    changePic(row) {
      if (!row.stff_labr_in_time) {
        row.stff_labr_try_date = '';
        row.stff_labr_try_time = '';
      }
      if (row.stff_labr_try_date) {
        this.selectDate(row);
      }
    },
    //试用到期日
    tryChange(row) {
      let data = row.stff_labr_try_time.getTime();
      row.stff_labr_try_time = '';
      row.stff_labr_try_date = '';
      row.stff_labr_try_time = data;
      row.stff_probation_date = data + 60 * 60 * 24 * 1000;
    },
    //选择合同类型
    contChange(row) {
      if (this.stffForm.stff_labr_list.length > 0) {
        if (this.stffForm.stff_labr_list[this.stffForm.stff_labr_list.length - 1].stff_labr_cont) {
          switch (this.stffForm.stff_labr_list[this.stffForm.stff_labr_list.length - 1].stff_labr_cont) {
            case 1:
              this.stffForm.stff_hire_tap = '返聘';
              break;
            case 2:
              this.stffForm.stff_hire_tap = '实习';
              break;
            case 3:
              this.stffForm.stff_hire_tap = '劳务';
              break;
            case 4:
              this.stffForm.stff_hire_tap = '劳动';
              break;
            case 6:
              this.stffForm.stff_hire_tap = '兼职';
              break;
            case 7:
              this.stffForm.stff_hire_tap = '无需';
              break;
            default:
              this.stffForm.stff_hire_tap = '';
          }
          if (this.stffForm.stff_labr_list[this.stffForm.stff_labr_list.length - 1].stff_labr_cont == 7) {
            if (this.stffForm.stffEnterTime) {
              row.stff_labr_in_time = this.stffForm.stffEnterTime;
            }
          }
        }
      }
    },
    enterTimeChange(val) {
      this.stffForm.stff_labr_list.forEach(item => {
        if (item.stff_labr_cont == 7) {
          item.stff_labr_in_time = val;
        }
      });

      if (this.stffForm.stffLeaveTime) {
        let start = val.getTime();
        let end = this.stffForm.stffLeaveTime.getTime();
        if (start > end) {
          this.stffForm.stffLeaveTime = null;
          this.$message.warning('入职时间不可以早于离职时间');
        }
      }
    },
    LeaveTimeChange(val) {
      if (this.stffForm.stffEnterTime) {
        let start = this.stffForm.stffEnterTime.getTime();
        let end = val.getTime();
        if (start > end) {
          this.stffForm.stffLeaveTime = null;
          this.$message.warning('入职时间不可以早于离职时间');
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vd_button {
  margin-top: 32px;
  border-top: 1px dashed $color-secondary-text;
  padding-top: 16px;
}
.vd_button_group {
  padding-bottom: 16px;
  margin-bottom: 32px;
  border-bottom: 1px solid $color-secondary-text;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}
.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}
.avatar {
  width: 136px;
  height: 136px;
  display: block;
}
</style>
